import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { QuoteMutationTypes } from '@/core/enum/Quotes/Mutations';
import { QuoteActionTypes } from '@/core/enum/Quotes/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { compare_quote_payload } from '@/core/types';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [QuoteActionTypes.GET_QUOTES](
    { commit }: AugmentedActionContext,
    payload:compare_quote_payload
  );

  [QuoteActionTypes.PROCEED_REVIEW](
    { commit }: AugmentedActionContext,
    payload:any
  );
  
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [QuoteActionTypes.GET_QUOTES]({ commit },payload) {
    try {
      const data = await axios.post(Links.GET_QUOTES, payload);
      commit(QuoteMutationTypes.SET_QUOTES, data.data.data.quotes);
      commit(QuoteMutationTypes.SET_INFORMATIONS, data.data.data.customer);
      commit(QuoteMutationTypes.SET_LEAD_INFORMATIONS, data.data.data.lead);
      commit(QuoteMutationTypes.SET_WHATSAPP_TEMPLATE, data.data.data.whatapp_template);
      if(data.data.data.hasError) commit(QuoteMutationTypes.SET_HAS_ERROR, data.data.data.hasError)
  }
    catch (err) {
      commit(QuoteMutationTypes.SET_HAS_ERROR, true)
      return err;
    }
  },

  async [QuoteActionTypes.PROCEED_REVIEW]({ commit },payload) {

    const data = await axios.post(Links.PROCEED_REVIEW, payload);
    window.localStorage.setItem('interested_policy',JSON.stringify(data.data.data.leads));
    window.localStorage.setItem('device_uuid', data.data.data.device_uuid);
    window.localStorage.setItem('selected_premium',JSON.stringify(payload.selected_premium));
    window.localStorage.setItem("traveller_index", JSON.stringify(0));
    window.localStorage.setItem('session_id', data.data.data.session_id);
    commit(QuoteMutationTypes.SET_INTERESTED_POLICY, data.data.data);
    const appendPath = router.currentRoute.value.query ?? '';
    const routeName = 'ReviewDetails'
    const route = router.resolve({ name: routeName, query: appendPath });
    
    window.location.href = route.href;
  },

  async [QuoteActionTypes.GET_VAT]({ commit }) {

    const data = await axios.post(Links.GET_VAT);
    commit(QuoteMutationTypes.SET_VAT, data.data.data.vat);
  },

  async [QuoteActionTypes.SEND_EMAIL]({ commit }, payload) {
    try {
      await axios.post(Links.SEND_EMAIL, payload);
    }
    catch (err) {
      return err;
    }
  }
};
