/* eslint-disable */
import { store } from "@/store";
import { App } from "vue";
import { ReviewActionTypes } from "@/core/enum/Review/Actions";
import { ReviewMutationTypes } from "@/core/enum/Review/Mutations";
import { Result, Generics } from '@/core/types';

class reviewDetails{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        reviewDetails.vueInstance = app;
    }

    public static async post(payload) {
        return await store
        .dispatch(ReviewActionTypes.POST_TRAVELLER_DETAILS, payload);
    }
    
    public static async getDetails(payload) {
        return await store.dispatch(ReviewActionTypes.GET_TRAVELLER_DETAILS, payload);
    }
    
    public static async getDetailsCheckout(payload) {
        return await store.dispatch(ReviewActionTypes.GET_TRAVELLER_DETAILS_CHECKOUT, payload);
    }
    
    public static setCustomer(payload: any) {
        store.commit(ReviewMutationTypes.SET_TRAVELLER, payload);
    }

    public static setLoading(payload: boolean) {
        store.commit(ReviewMutationTypes.SET_TRAVELLER_LOADING, payload);
    }
}

export default reviewDetails;