
import { defineComponent, computed } from 'vue'
import { store } from '@/store';

export default defineComponent({
    setup() {

        const language = computed(() => {
            return store.state.lang.lang
        })
        const homePage = process.env.VUE_APP_PAYMENT_GATEWAY_URL + `/` + language.value;

       
        return {
            homePage
        }
    },
})
