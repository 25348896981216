
import { computed, defineComponent, ref, watchEffect } from "vue";
import { store } from "@/store";
import personalDetails from "@/core/services/PersonalDetails";
import { formPersonalDetails, screen } from "@/store/stateless";
import router from "@/router";
import { formMemberDetails } from "@/store/stateless/forms";

export default defineComponent({
  setup(_, context) {
    // const open = ref(false);

    const existingLeads = computed(() => {
      return store.getters.getAllExistingLeads;
    });

    const uaeId = computed(() => {
      return store.state.countries.uae_id;
    });

    // watchEffect(() => {
    //   if (existingLeads.value.length > 0) open.value = true;
    // });

    const open = computed(() => {
      return existingLeads.value.length > 0 ? true : false
    })

    const setDetails = (value) => {
      window.localStorage.setItem("traveller_index", JSON.stringify(0));
      // let appendPath = router.currentRoute.value.query ?? '';
      // const uuid = window.localStorage.getItem('uuid')

      // if(Object.keys(appendPath).length === 0 && uuid) {
      //   appendPath = { uuid }
      // }

      const queryRoute = router.currentRoute.value.query
      if(queryRoute.uuid) delete queryRoute.uuid
      queryRoute.uuid = value.uuid

      const route = router.resolve({ name: value.routeName, query: queryRoute });
      window.location.href = route.href;
    };

    const newDetails = () => {
      const agentId = router.currentRoute.value.query && router.currentRoute.value.query.agent_id ? router.currentRoute.value.query.agent_id.toString() : "";
      window.localStorage.removeItem("map_id");
      store.state.personals.customer.agent_id = agentId ? parseInt(agentId) : 0;
      store.state.personals.customer.no_of_traveller = 1;
      store.state.personals.customer.destination_id = [];
      store.state.personals.customer.source_id = uaeId.value;
      store.state.personals.customer.inbound_outbound = true;
      personalDetails.setCustomer(store.state.personals.customer);
      formPersonalDetails.value?.resetFields();

      // formMemberDetails.value?.resetFields();
      // open.value = false;
      store.commit('SET_CUSTOMER_RECENT_LEADS', [])
      context.emit('reset')
      personalDetails.setExistClick(true);
    };

    return {
      formPersonalDetails,
      formMemberDetails,
      existingLeads,
      screen,
      open,
      setDetails,
      newDetails,
    };
  },
});
