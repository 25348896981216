
import { currency } from "@/core/functions/currency";
import Quotes from "@/core/services/Quotes";
import router from "@/router";
import { store } from "@/store";
import { defineComponent, ref, toRef, computed } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import moment from "moment";

export default defineComponent({
    props: {
        quote: {
            type: Object,
            required: true
        },
        expand: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    setup(_, context) {

        const captcha = useReCaptcha();

        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)

        const quote = toRef(_, 'quote');
        const moreDetails = ref(false);
        const assetDomain = process.env.VUE_APP_ASSETS_DOMAIN;
        
        const premiums = quote.value?.premium;
        const selectedPremium = ref(premiums)
        
        const recent_searches = computed(() => {
            return store.state.personals.recent_searches;
        });
        
        const customer = computed(() => {
            return store.state.personals.customer;
        });

        const BuyNow = async (quote) => {
            // const loading = ElLoading.service({
            //     lock: true,
            //     text: 'Loading',
            //     background: 'rgba(0, 0, 0, 0.9)',
            // })


            await captcha?.recaptchaLoaded();
            // execute reCaptcha
            const token = await captcha?.executeRecaptcha("login");
            const find = recent_searches.value.find(x => x.bai_misc_lead_id === customer.value.id);
            if(find) {
                find.stage = (find.stage && find.stage > 2) ? find.stage :  2;
                find.insurance_provider_id = find.insurance_provider_id ?? quote.value.provider.id;
                find.plan_id = find.plan_id ?? quote.value.provider_plan_id;
                find.plan_ref_id = find.plan_ref_id ?? quote.value.coverage_id;
                find.last_visit = moment().format('YYYY-MM-DD');
                find.device_uuid = find.device_uuid ?? null;
                find.bai_misc_lead_id = find.bai_misc_lead_id ?? customer.value.id;
                find.status = find.status ?? 0;
                find.lead_details = customer.value.travellers_info
            }        
            console.log('quote ', quote);
    
            // eslint-disable-next-line
            const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
            const sessionId = window.localStorage.getItem('session_id');
            if(uuid) {
                const agent_id = router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
                const payload = {
                    uuid: uuid,
                    selected_premium: selectedPremium.value,
                    policy_id: quote.id,
                    quote: quote,
                    agent_id: agent_id,
                    recent_searches: find,
                    session_id: sessionId ?? ''
                }
                if (token) {
                    window.localStorage.setItem("traveller_index", JSON.stringify(0));
                    await Quotes.buyNow(payload);
                }
            } else {
                const appendPath = router.currentRoute.value.query ?? '';
                const routeName = 'TravellerDetails'
                const route = router.resolve({ name: routeName, query: appendPath });
                
                window.location.href = route.href;
            }

            // loading.close()
        }

        return {
            premiums,
            selectedPremium,
            assetDomain,
            moreDetails,
            domain,
            BuyNow,
            url
        }
    }
});
