
  import Quotes from "@/core/services/Quotes";
  import { store } from "@/store";
  import { computed, defineComponent, ref, toRef, inject } from "vue";
  import { useReCaptcha } from "vue-recaptcha-v3";
  import moment from "moment";
  import router from "@/router";

  export default defineComponent({
    props: {
      quote: {
        type: Object,
        required: true,
      },
      expand: {
        type: Boolean,
        required: false,
        default: true,
      },
      drawer: {
        type: Boolean,
        required: false,
        default: true,
      },
      position: {
        type: Number,
        required: true,
      }
    },

    setup(_) {
      const captcha = useReCaptcha();

      const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
      const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

      const quote = toRef(_, "quote");
      const moreDetails = ref(false);
      const assetDomain = process.env.VUE_APP_ASSETS_DOMAIN;
      const premiums = quote.value.premium;
      const selectedPremium = ref(premiums);

      const recent_searches = computed(() => {
        return store.state.personals.recent_searches;
      });

      const customer = computed(() => {
        return store.state.personals.customer;
      });

      const language = computed(() => {
        return window.localStorage.getItem("language");
      });

      const isMobile = inject('isMobile')

      const addonAdd = (val, original) => {
        if (val.addonCheck) {
          original.premium = parseFloat(original.premium) + parseFloat(val.value);
          original.premium_round = parseFloat(original.premium_round) + parseFloat(val.value_to_display);
          val.prev_price = parseFloat(val.value);
          val.prev_price_round = parseFloat(val.value_to_display);
        } else {
          original.premium = parseFloat(original.premium) - parseFloat(val.prev_price);
          original.premium_round = parseFloat(original.premium_round) - parseFloat(val.prev_price_round);
        }
      };

      const compareList = computed(() => {
        return store.state.quotes.compare_list;
      });

      const viewBenefits = (benefitsArray, quote_id) => {
        store.state.quotes.quote_benefit_id = quote_id;
        store.state.quotes.benefitsArray = benefitsArray;
      };
      function moreDetailsSection(quoteS, section) {
        if (quoteS && quoteS?.highlight.length) {
          return quoteS?.highlight.filter((x) => {
            if (x.section == section) {
              return x;
            }
          });
        }
        return [];
      }

      const BuyNow = async (quote) => {
        // const loading = ElLoading.service({
        //   lock: true,
        //   text: "Loading",
        //   background: "rgba(0, 0, 0, 0.9)",
        // });
        await captcha?.recaptchaLoaded();
        // execute reCaptcha
        const token = await captcha?.executeRecaptcha("login");

        const find = recent_searches.value.find(
          (x) => x.bai_misc_lead_id === customer.value.id
        );
        if (find) {
          find.stage = find.stage && find.stage > 2 ? find.stage : 2;
          find.insurance_provider_id =
          find.insurance_provider_id ?? quote.value.provider.id;
          find.plan_id = find.plan_id ?? quote.value.provider_plan_id;
          find.plan_ref_id = find.plan_ref_id ?? quote.value.coverage_id;
          find.last_visit = moment().format("YYYY-MM-DD");
          find.device_uuid = find.device_uuid ?? null;
          find.bai_misc_lead_id = find.bai_misc_lead_id ?? customer.value.id;
          find.status = find.status ?? 0;
        }
        // eslint-disable-next-line
        const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;       
        const agent_id = router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
        const sessionId = window.localStorage.getItem('session_id');
        const payload = {
          uuid: uuid,
          selected_premium: selectedPremium.value,
          policy_id: quote.id,
          quote: quote,
          agent_id: agent_id,
          recent_searches: find,
          session_id: sessionId ?? ''
        };
        if (token) {
          window.localStorage.setItem("traveller_index", JSON.stringify(0));
          await Quotes.buyNow(payload);
        }

        // loading.close();
      };

      const leadInfo = computed(() => {
        return store.state.quotes.lead
      });

      return {
        premiums,
        isMobile,
        selectedPremium,
        moreDetailsSection,
        viewBenefits,
        language,
        addonAdd,
        assetDomain,
        moreDetails,
        domain,
        url,
        BuyNow,
        compareList,
        leadInfo,
      };
    },
  });
