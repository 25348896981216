import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { RecentSearches } from '@/core/types';
import { State } from './state';

export type Getters = {
  getCustomer(state: State): RecentSearches;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCustomer: (state) => state.recent_searches,
};