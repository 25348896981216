import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Travel } from '@/core/types';
import { State } from './state';
import { setDateOnly } from '@/store/stateless'

export type Getters = {
  getRecentSearches(state: State): Travel;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getRecentSearches: (state) => state.customer,

  getAllExistingLeads: (state) => {
    if(state.recent_searches.length > 0) {
      return state.recent_searches.map(x => {
        if(x.bai_misc_lead_id != null && x.bai_misc_lead_id > 0) {
          let routeName = '';
          const currentStage = x.stage;
          if(currentStage == 1) {
            routeName = 'CompareQuotes';
          } else if(currentStage == 2) {
            routeName = 'ReviewDetails';
          } else if(currentStage == 3) {
            routeName = 'CheckoutDetails';
          }
          let html = `<p> ${x.lead_details.length} ${x.lead_details.length > 1 ? 'Travellers' : 'Traveller'} `;
          if(x.provider_name != null && x.provider_name != '' && x?.provider_name != undefined) {
            html += `<span> - ${x?.provider_name} </span>`;
          }
          if(x?.destination_name != null && x?.destination_name != '' && x?.destination_name != undefined) {
            html += `<span> - to ${x?.destination_name} </span>`;
          }

          if(x?.start_date != null && x?.start_date != '' && x?.start_date != undefined) {
            html += `<span> - on ${x?.start_date} </span>`;
          }
          
          html += `</p>`;
          html += `<span class="visited-date"> Last Visited on : ${setDateOnly(x.last_visit)} </span>`;
          return {
            map_lead_id: x.bai_misc_lead_id,
            stage:x.stage,
            routeName: routeName,
            name: html,
            uuid: x.uuid_lead
          }
        }
      })
    }
    return []
  }
};