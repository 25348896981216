<template>
    <section class="compare-vechile page-content">
        <div class="container">
            <div class="compare-vechile-container main-container">
                <div class="compare-vechile_row">
                    <div class="page-title plain">
                        <div class="d-flex justify-content-between align-items-center">
                            <h2 class="mb-0">{{ $t('compare_plan') }} </h2>
                            <div class="custom-button text-center">
                                <a @click="$router.go(-1)" class="btn"> {{ $t('back') }} </a>
                            </div>
                        </div>
                    </div>

                    <div class="compare-plans-table">
                        <div class="compare-plans-table_block">
                            <div class="compare-plans-table_block_item">
                                <div class="compare-plans-table_block_item_row">
                                    <div class="row">
                                        <div class="col-lg-3 left"></div>
                                        <div class="col-lg-3" v-for="(data, index) in compareList" :key="index">
                                            <h-compare-details v-bind:quote="data" />

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!-- <template v-if="checkIfAddon?.length > 0">
                                <div class="compare-plans-table_block_item">
                                    <div class="compare-plans-table_block_item_row">
                                        <div class="row">
                                            <div class="col-lg-3 left">
                                                <div class="left-table-col">
                                                    <p class="fw-bolder"> {{ $t('addons') }} </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3" v-for="(addon, indexV) in addons" :key="indexV">
                                                <ul class="compare-plans-table_block_item_row_item" >
                                                    <li class="desc-p" v-for="(add, d) in addon" :key="d + indexV">
                                                        <p class="amount">
                                                            {{ add.label }}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </template> -->
                            
                            <template v-if="compareList.length > 0">
                                
                                <div class="compare-plans-table_block_item" v-for="(highlight, index) in getHighPriority" :key="index">
                                    <div class="compare-plans-table_block_item_row">
                                        <div class="row">
                                            <div class="col-lg-3 left">
                                                <div class="left-table-col">
                                                    <p>{{ language === 'ar' ? highlight.label_ar : highlight.label }}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3" v-for="(highlightV, indexV) in compareList" :key="indexV">
                                                <div class="compare-plans-table_block_item_row_item">
                                                    <div class="desc-p">
                                                        <p class="amount"><template v-if="highlightValue(highlightV.highlight, highlight.label) != ''"> {{ highlightValue(highlightV.highlight, highlight.label) }} </template></p>
                                                    </div>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                                
                                <template v-if="getAddonsWith.length > 0">
                                <div class="compare-plans-table_block_item" v-for="(addon, index) in getAddonsWith" :key="index">
                                    <div class="compare-plans-table_block_item_row">
                                        <div class="row">
                                            <div class="col-lg-3 left">
                                                <div class="left-table-col">
                                                    <p>{{ language === 'ar' ? addon.label_ar : addon.label }}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3" v-for="(highlightV, indexV) in compareList" :key="indexV">
                                                <div class="compare-plans-table_block_item_row_item">
                                                    <div class="desc-p">
                                                        <p class="amount">{{ addonValue(highlightV.addon, addon.label) }}</p>
                                                    </div>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                                </template>
                                
                                <div class="compare-plans-table_block_item" v-for="(highlight, index) in getLeastPriority" :key="index">
                                    <div class="compare-plans-table_block_item_row">
                                        <div class="row">
                                            <div class="col-lg-3 left">
                                                <div class="left-table-col">
                                                    <p>{{ language === 'ar' ? highlight.label_ar : highlight.label }}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3" v-for="(highlightV, indexV) in compareList" :key="indexV">
                                                <div class="compare-plans-table_block_item_row_item">
                                                    <div class="desc-p">
                                                        <p class="amount"><template v-if="highlightValue(highlightV.highlight, highlight.label) != ''">{{ highlightValue(highlightV.highlight, highlight.label) }} </template></p>
                                                    </div>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>

                            </template>

                            


                            <div class="compare-plans-table_block_item">
                                <div class="compare-plans-table_block_item_row">
                                    <div class="row">
                                        <div class="col-lg-3 left"></div>
                                        <div class="col-lg-3" v-for="(data, index) in compareList" :key="index">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="proceed">
                                                    <a @click.prevent="BuyNow(data)" class="btn"> {{ $t('proceed') }} </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { defineComponent, computed, ref } from "vue";
import { store } from "@/store";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";
import Quotes from "@/core/services/Quotes";
import moment from "moment";

export default defineComponent({

    setup() {
        const captcha = useReCaptcha();

        const winterSports = 'winter sports cover'


        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

        const compareList = computed(() => {
            const data = JSON.parse(window.localStorage.getItem("compare_data"));
            return data
            // return store.state.quotes.compare_list
        });

        const recent_searches = computed(() => {
            return store.state.personals.recent_searches;
        });

        const customer = computed(() => {
            return store.state.personals.customer;
        });


        function highlightValue(highlightArr, highlightV) {
            const currentH = highlightArr.find((x) => x.label == highlightV)
            if(currentH != undefined && currentH.value != '') {
                return currentH.value;
            }
            return '-';
        }

        function addonValue(highlightArr, highlightV) {
            const currentH = highlightArr.find((x) => x.label == highlightV)
            if(currentH != undefined && currentH.value != '') {
                return 'Add on';
            }
            return '-';
        }



        // handleClick
        const BuyNow = async (quote) => {
            await captcha?.recaptchaLoaded();
            // execute reCaptcha
            const token = await captcha?.executeRecaptcha("login");

            const find = recent_searches.value.find(x => x.bai_misc_lead_id === customer.value.id);
            if(find) {
                find.stage = (find.stage && find.stage > 2) ? find.stage :  2;
                find.insurance_provider_id = find.insurance_provider_id ?? quote.value.provider.id;
                find.plan_id = find.plan_id ?? quote.value.provider_plan_id;
                find.plan_ref_id = find.plan_ref_id ?? quote.value.coverage_id;
                find.last_visit = moment().format('YYYY-MM-DD');
                find.device_uuid = find.device_uuid ?? null;
                find.bai_misc_lead_id = find.bai_misc_lead_id ?? customer.value.id;
                find.status = find.status ?? 0;
            } 

            // eslint-disable-next-line
            const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
            const sessionId = window.localStorage.getItem('session_id');
            if(uuid) {
                const agent_id = router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
                const payload = {
                    uuid: uuid,
                    selected_premium: quote.premium,
                    policy_id: quote.id,
                    quote: quote,
                    agent_id: agent_id,
                    recent_searches: find,
                    session_id: sessionId ?? ''
                }
                if (token) {
                    window.localStorage.setItem("traveller_index", JSON.stringify(0));
                    await Quotes.buyNow(payload);
                }
            } else {
                const appendPath = router.currentRoute.value.query ?? '';
                const routeName = 'TravellerDetails'
                const route = router.resolve({ name: routeName, query: appendPath });
                
                window.location.href = route.href;
            }

            // loading.close();
        };

        const language = computed(() => {
            return window.localStorage.getItem("language");
        });

        const getAddonsWith = computed(() => {
            const data = compareList.value.flatMap(x => x.addon)
            return removeDuplicates(data, x => x.label)
        })

        const addons = computed(() => {
            return compareList.value.map(x => {
                return x.addon.filter(y => y.addonCheck)
            })
        })

        
        // const getAddonsWith = computed(() => {
        //     var largest= compareList.value[0].addon

        //     for (let i=0 ; i < compareList.value.length; i++){
        //         if (compareList.value[i].addon.length > largest.length) {
        //             largest= compareList.value[i].addon ;
        //         }
        //     }

        //     return largest
        // })

        const isAddonExist = computed(() => {
            const exist = getAddonsWith.value.filter(x => x.label.toLowerCase() == winterSports)
            return exist.length > 0 ? true : false
        })

        
        const getHighPriority = computed(() => {
            return compareList.value[0].highlight.slice(0, 7)
        })
        
        const getLeastPriority = computed(() => {
            const priorities = getHighPriority.value.map(x => x.label)
            let data = compareList.value[0].highlight.filter(x => !priorities.includes(x.label))

            if(isAddonExist.value) return data.filter(x => x.label.toLowerCase() != winterSports)

            return data
        })

        function removeDuplicates(data, key) {
            return [
                ...new Map(
                    data.map(x => [key(x), x])
                ).values()
            ]
        }


        return {
            isAddonExist,
            addonValue,
            getAddonsWith,
            getHighPriority,
            getLeastPriority,
            addons,
            // checkIfAddon,
            language,
            captcha,
            compareList,
            highlightValue,
            domain,
            url,
            BuyNow,
        };
    },
});
</script>
  