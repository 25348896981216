import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { ReviewMutationTypes } from '@/core/enum/Review/Mutations';
import { ReviewActionTypes } from '@/core/enum/Review/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { Travel } from '@/core/types';
import router from '@/router';
import { loading } from "@/store/stateless"

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ReviewActionTypes.POST_TRAVELLER_DETAILS](
    { commit }: AugmentedActionContext,
    payload: Travel
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ReviewActionTypes.POST_TRAVELLER_DETAILS]({ commit }, payload) {
    commit(ReviewMutationTypes.SET_TRAVELLER_LOADING, true);
    try {
      const data = await axios.post(Links.POST_TRAVELLER_DETAILS, payload);
      const personal_details = data.data.data;
      commit(ReviewMutationTypes.SET_TRAVELLER_RESULT, personal_details);
      commit(ReviewMutationTypes.SET_TRAVELLER_LOADING, false);
      window.localStorage.setItem('session_id', personal_details.session_id);
      const setCustomer = customer(personal_details.leads, payload);
      const traveller_index:any = window.localStorage.getItem('traveller_index');

      // commit(ReviewMutationTypes.SET_TRAVELLER_MEMBERS, setCustomer);
     

      if(parseInt(traveller_index) == (setCustomer.travellers_info.length - 1)) {
        const appendPath = router.currentRoute.value.query ?? '';
        const routeName = 'CheckoutDetails'
        const route = router.resolve({ name: routeName, query: appendPath });
        window.location.href = route.href;
      } else {
        // window.localStorage.setItem('traveller_index', JSON.stringify((parseInt(traveller_index) + 1)))
      }
      
    }
    catch (err) {
      setTimeout(() => {
        loading.value = false
      }, 500);
      return err;
    }
  },

  async [ReviewActionTypes.GET_TRAVELLER_DETAILS] ({ commit }, payload) {
    try {
      const data = await axios.post(Links.GET_LATEST_LEADS_BY_LEAD_ID, payload)       
      const personal_details = data.data.data;
      const filtereCustomer = customer(personal_details.customer, payload);
      commit(ReviewMutationTypes.SET_TRAVELLER_MEMBERS, filtereCustomer);
    } catch (err) {
      return err
    }
  },
  async [ReviewActionTypes.GET_TRAVELLER_DETAILS_CHECKOUT] ({ commit }, payload) {
    try {
      const data = await axios.post(Links.GET_LATEST_LEADS_BY_UUID, payload)       
      const personal_details = data.data.data;
      const filtereCustomer = customer(personal_details.customer, payload);
      commit(ReviewMutationTypes.SET_TRAVELLER_MEMBERS, filtereCustomer);
      commit(ReviewMutationTypes.SET_WHATSAPP_TEMPLATE, personal_details.whatapp_template);
    } catch (err) {
      return err
    }
  },
};



function customer(data, payload) {
  const { lead_details, get_customer, get_invoice }  = data
  const sessionId = window.localStorage.getItem('session_id');     
  const agent = router.currentRoute.value.query && router.currentRoute.value.query.agent_id ? router.currentRoute.value.query.agent_id.toString() : "";
                    
  const travel = {
    id: data.id,
    lang: payload.lang,
    name: data.name,
    is_self: data.is_self === 1 ? true : false,
    inbound_outbound: (data.source_id === 188 || data.source_id === null),
    email: get_customer ? get_customer.email : null,
    phone_number: get_customer ? get_customer.phone_number_without_code : null,
    phone_country_code: get_customer ? get_customer.phone_country_code : null,
    complete_phone_number: null,
    agent_id: get_customer ? get_customer.agent_id : (agent ? parseInt(agent) : null),
    no_of_traveller: (lead_details.length > 0 ) ? lead_details.length : 1,
    destination_id: data.destination_id,
    source_id: data.source_id,
    trip_type: data.trip_type,
    product_type: data.product_type,
    start_date: data.start_date,
    end_date: data.end_date,
    destination_country:data.destination_country,
    no_of_days: data.no_of_days,
    is_travel: 1,
    source: data.customer_source,
    utm_source: data.utm_source,
    utm_medium: data.utm_medium,
    utm_campaign: data.utm_campaign,
    utm_content: data.utm_content,
    invoice:get_invoice ? get_invoice : null,
    deleted_members:[],
    travellers_info: lead_details && lead_details.length > 0 ? members(lead_details) : [],
    session_id:sessionId ? sessionId : null,
    plan_documents: data.plan_documents ? data.plan_documents : null,
  }
  return travel;
}

function members(leads) {
  return leads.map((x, index) => {
    let lead = x;
    if(x.lead_information != undefined) {
      lead = x.lead_information;
    }
    return {
      id: lead.id,
      bai_misc_lead_id: lead.bai_misc_lead_id,
      first_name: lead.first_name,
      middle_name: lead?.middle_name,
      last_name: lead?.last_name,
      type: lead?.type,
      is_field: lead?.is_field,
      date_of_birth: lead.date_of_birth,
      dob: lead.dob,
      passport: lead.passport,
      gender_id: lead.gender_id,
      marital_status_id: lead.marital_status_id,
      nationality_id: lead.nationality_id,
      country_id: lead.country_id,
      relationship_type_id: lead.relationship_type_id == null && leads.length === 0 ? 5 : lead.relationship_type_id ,
      is_editable: (!index) ? true : false
    }
  })
}