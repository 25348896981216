export enum QuoteMutationTypes {
    SET_QUOTES = 'SET_QUOTES',
    SET_INFORMATIONS = 'SET_INFORMATIONS',
    SET_WHATSAPP_TEMPLATE = 'SET_WHATSAPP_TEMPLATE',
    SET_INTERESTED_POLICY = 'SET_INTERESTED_POLICY',
    SET_HAS_ERROR = 'SET_HAS_ERROR',
    SET_SELECTED_PREMIUM = 'SET_SELECTED_PREMIUM',
    SET_VAT = 'SET_VAT',
    SET_LEAD_INFORMATIONS = 'SET_LEAD_INFORMATIONS',
  }
  