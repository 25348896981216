
import { defineComponent, onMounted, provide } from "vue";
import Language from "@/core/services/Language";
import PersonalDetails from '@/core/services/PersonalDetails';
import router from "./router";
import { isCalled } from '@/store/stateless';

export default defineComponent({
  setup() {
    // const currentInstance = getCurrentInstance();
    // const dates = currentInstance?.appContext.config.globalProperties.dates;
    onMounted(async () => {
      const routeLanguage =
        router.currentRoute.value.query && router.currentRoute.value.query.lang
          ? router.currentRoute.value.query.lang.toString()
          : "";
      const language = window.localStorage.getItem("language"); 

      const routeUUID =
        router.currentRoute.value.query && router.currentRoute.value.query.uuid
          ? router.currentRoute.value.query.uuid.toString()
          : "";

      const agent_id =
        router.currentRoute.value.query && router.currentRoute.value.query.agent_id
          ? router.currentRoute.value.query.agent_id.toString()
          : "";
          if(agent_id) {
            window.localStorage.removeItem('uuid')
            window.localStorage.removeItem('personal_details')
            window.localStorage.removeItem('device_uuid')
            window.localStorage.removeItem('agent_id')
          }
      
      Language.setLanguage("en");
      // if (routeLanguage) {
      //   Language.setLanguage(routeLanguage);
      // } else {
      //   if (!language) {
      //     Language.setLanguage("en");
      //   }
      // }
        
      if(routeUUID) {
        window.localStorage.setItem('uuid', routeUUID);
      }

      const routeAgent =
        router.currentRoute.value.query && router.currentRoute.value.query.agent_id
          ? router.currentRoute.value.query.agent_id.toString()
          : "";
      if(routeAgent) {
        window.localStorage.setItem('agent_id', routeAgent)
      }
      const pd = window.localStorage.getItem('personal_details');
      if(pd) {
        if(routeAgent == '') {
          PersonalDetails.setCustomerLeads(JSON.parse(pd));
        }
      }
    });
    
    const isMobile = window.innerWidth < 960
    provide('isMobile', isMobile)
    return {
      isMobile
    }
  },
});
