import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { Generics } from '@/core/types';

import { State } from './state';

export type Getters = {
  getInsuredType(state: State): Array<Generics> | null;
  getGender(state: State): Array<Generics> | null;
  getMaritalStatus(state: State): Array<Generics> | null;
  getRelationTypes(state: State): Array<Generics> | null;
  getPeriodTypes(state: State): Array<Generics> | null;
  getTripTypes(state: State): Array<Generics> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getInsuredType: (state) => state.product_types,
  getGender: (state) => state.genders,
  getMaritalStatus: (state) => state.marital_status,
  getRelationTypes: (state) => state.relation_types,
  getPeriodTypes: (state) => state.period_types,
  getTripTypes: (state) => state.trip_types,
};
